
import { defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

import Store from '@/store';

import useEmitter from '@/compostables/useEmitter';

import Status from '@/components/context-menu/Status.vue';
import User from '@/components/context-menu/User.vue';
import Date from '@/components/context-menu/Date.vue';
import Group from '@/components/context-menu/Group.vue';

export default defineComponent({
	components: {
		Status,
		User,
		Date,
		Group,
	},

	setup() {
		const contextMenu = Store.get('header.contextMenu');

		const emitter = useEmitter();

		const width = ref(0);
		const height = ref(0);
		const index = ref(0);

		function closeOnEscape() {
			if (!contextMenu.value) {
				return;
			}

			contextMenu.value.active = false;
		}

		onMounted(() => {
			emitter.on('escape', closeOnEscape);
		});

		onUnmounted(() => {
			emitter.off('escape', closeOnEscape);
		});

		// Set index to initial when the context menu is closed
		watch(
			() => contextMenu.value?.active,
			(value) => {
				if (!value) {
					setTimeout(() => {
						index.value = 0;
					}, 250);
				}
			}
		);

		// Set the height of the context menu to equal the height of the active slide
		watch(index, () => {
			nextTick(() => {
				const activeElement = document.querySelector('[data-active="true"]') as HTMLElement;

				width.value = activeElement?.offsetWidth || 0;
				height.value = activeElement?.scrollHeight > 350 ? 350 : activeElement?.scrollHeight || 0;
			});
		});

		function remove() {
			emitter.emit('bulk:remove');
		}

		function exportCSV() {
			emitter.emit('export-csv');
		}

		return {
			// data
			contextMenu,
			index,
			width,
			height,

			// functions
			remove,
			exportCSV,
		};
	},
});
