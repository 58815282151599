
import { defineComponent, ref } from 'vue';

import Store from '@/store';

import SVG from '@/components/SVG.vue';
import AuthService from '@/services/auth.service';
import { useRouter } from 'vue-router';

export default defineComponent({
	components: {
		SVG,
	},

	setup() {
		const router = useRouter();

		const active = ref(false);

		async function logout() {
			await AuthService.logout().then((response) => {
				if (response) {
					Store.set('projects', []);
					router.push({ name: 'Login' });
				}
			});
		}

		return {
			// data
			active,

			// functions
			logout,
		};
	},
});
