import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Search = _resolveComponent("Search")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_User = _resolveComponent("User")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["fixed top-0 left-0 w-72 max-w-full h-screen z-50 flex flex-col justify-between py-6 bg-white border-r border-[#e7e8ed] transition", { '-translate-x-full sm:translate-x-0': !_ctx.active }])
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, {
        to: { name: 'Projects' },
        class: "flex justify-center mb-6"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_SVG, {
              name: "logo",
              class: "w-44"
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Search),
      (_ctx.$user?.can('view projects'))
        ? (_openBlock(), _createBlock(_component_MenuItem, {
            key: 0,
            name: "Projects",
            label: "Verkætlanir",
            icon: "icons/home"
          }))
        : _createCommentVNode("", true),
      (_ctx.$user?.can('view meetings'))
        ? (_openBlock(), _createBlock(_component_MenuItem, {
            key: 1,
            name: "MeetingCards",
            label: "Fundir",
            icon: "icons/meeting"
          }))
        : _createCommentVNode("", true),
      (_ctx.$user?.can('update users'))
        ? (_openBlock(), _createBlock(_component_MenuItem, {
            key: 2,
            name: "Users",
            label: "Brúkarar",
            icon: "icons/users"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_User)
  ], 2)), [
    [_directive_click_away, _ctx.close]
  ])
}