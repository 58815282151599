import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative z-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Status = _resolveComponent("Status")!
  const _component_User = _resolveComponent("User")!
  const _component_Date = _resolveComponent("Date")!
  const _component_Group = _resolveComponent("Group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.height ? `height: ${_ctx.height}px !important; width: ${_ctx.width}px !important` : ''),
      class: _normalizeClass(["absolute right-0 w-56 py-3 rounded-md shadow-lg text-gray-900 bg-white transition-all transform overflow-hidden", !_ctx.contextMenu?.active && '-translate-y-1 opacity-0 pointer-events-none'])
    }, [
      _createElementVNode("div", null, [
        (_ctx.$user.can('update tasks'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.index = 1), ["stop","prevent"])),
              class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
            }, " Rætta status ", 2))
          : _createCommentVNode("", true),
        (_ctx.$user.can('update tasks'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: "#",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.index = 2), ["stop","prevent"])),
              class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
            }, " Rætta ábyrgd ", 2))
          : _createCommentVNode("", true),
        (_ctx.$user.can('update tasks'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              href: "#",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.index = 3), ["stop","prevent"])),
              class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
            }, " Rætta byrjan ", 2))
          : _createCommentVNode("", true),
        (_ctx.$user.can('update tasks'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 3,
              href: "#",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.index = 4), ["stop","prevent"])),
              class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
            }, " Rætta enda ", 2))
          : _createCommentVNode("", true),
        (_ctx.$user.can('delete tasks'))
          ? (_openBlock(), _createElementBlock("a", {
              key: 4,
              href: "#",
              onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)), ["stop","prevent"])),
              class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
            }, " Strika uppgávu/r ", 2))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("a", {
          href: "#",
          onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.index = 5), ["stop","prevent"])),
          class: _normalizeClass(["block px-7 py-3 hover:bg-dark-400/5 text-sm", { 'opacity-50 pointer-events-none': !_ctx.contextMenu.hasSelected }])
        }, " Flyt í bólk ", 2), [
          [_vShow, false]
        ]),
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.exportCSV && _ctx.exportCSV(...args)), ["stop","prevent"])),
          class: "block px-7 py-3 hover:bg-dark-400/5 text-sm"
        }, " Eksportera sum CSV ")
      ]),
      _createVNode(_component_Status, {
        active: _ctx.index == 1,
        onBack: _cache[7] || (_cache[7] = ($event: any) => (_ctx.index = 0))
      }, null, 8, ["active"]),
      _createVNode(_component_User, {
        active: _ctx.index == 2,
        onBack: _cache[8] || (_cache[8] = ($event: any) => (_ctx.index = 0))
      }, null, 8, ["active"]),
      _createVNode(_component_Date, {
        active: _ctx.index == 3,
        eventName: "bulk:expected-start",
        onBack: _cache[9] || (_cache[9] = ($event: any) => (_ctx.index = 0))
      }, null, 8, ["active"]),
      _createVNode(_component_Date, {
        active: _ctx.index == 4,
        eventName: "bulk:expected-end",
        onBack: _cache[10] || (_cache[10] = ($event: any) => (_ctx.index = 0))
      }, null, 8, ["active"]),
      _createVNode(_component_Group, {
        active: _ctx.index == 5,
        onBack: _cache[11] || (_cache[11] = ($event: any) => (_ctx.index = 0))
      }, null, 8, ["active"])
    ], 6)
  ]))
}