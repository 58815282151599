
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import Store from '@/store';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	props: {
		name: String,
		label: String,
		icon: String,
		collapsed: Boolean,
	},

	components: {
		SVG,
	},

	setup(props) {
		const route = useRoute();

		const isActive = computed(() => {
			return route.name == props.name;
		});

		function closeSidebar() {
			Store.set('sidebar', false);
		}

		return {
			isActive,

			closeSidebar,
		};
	},
});
