
import { defineComponent, onMounted, Ref, ref } from 'vue';

import SVG from '@/components/SVG.vue';
import useEmitter from '@/compostables/useEmitter';
import TaskStatus from '@/models/task-status.model';
import TaskStatusService from '@/services/task-status.service';
import { useRoute } from 'vue-router';

export default defineComponent({
	props: {
		active: Boolean,
	},

	emits: ['back'],

	components: {
		SVG,
	},

	setup() {
		const emitter = useEmitter();
		const route = useRoute();

		const statuses: Ref<TaskStatus[]> = ref([]);

		onMounted(async () => {
			await TaskStatusService.index(Number(route.params.id)).then((response) => {
				if (response) {
					statuses.value = response;
				}
			});
		});

		function select(status: TaskStatus) {
			emitter.emit('bulk:status', status);
		}

		return {
			// data
			statuses,

			// functions
			select,
		};
	},
});
