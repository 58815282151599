import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "fixed top-24 right-0 px-4 sm:px-0 sm:right-5 w-full max-w-sm z-30" }
const _hoisted_2 = { class: "pr-4" }
const _hoisted_3 = { class: "mb-[6px] text-dark leading-tight" }
const _hoisted_4 = { class: "text-gray-400 text-sm leading-tight" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createElementBlock("div", {
        key: toast.id,
        class: "relative flex space-x-4 mb-2 p-4 bg-white border border-[#e2e2e2] rounded-md shadow-sm"
      }, [
        _createElementVNode("div", null, [
          _withDirectives(_createVNode(_component_SVG, {
            name: "icons/toast-check",
            class: "w-5 h-5 text-green"
          }, null, 512), [
            [_vShow, toast.success]
          ]),
          _withDirectives(_createVNode(_component_SVG, {
            name: "icons/toast-exclamation",
            class: "w-5 h-5 text-red"
          }, null, 512), [
            [_vShow, !toast.success]
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(toast.title), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(toast.body), 1)
        ]),
        _createElementVNode("div", {
          onClick: ($event: any) => (_ctx.remove(toast.id)),
          class: "absolute top-3 right-3 text-gray-300 hover:text-gray transition cursor-pointer"
        }, [
          _createVNode(_component_SVG, {
            name: "icons/add",
            class: "w-3 h-3 transform rotate-45"
          })
        ], 8, _hoisted_5)
      ]))
    }), 128))
  ]))
}