
import { defineComponent } from 'vue';

import Store from '@/store';

import Task from '@/components/context-menu/types/Task.vue';
import Project from '@/components/context-menu/types/Project.vue';

export default defineComponent({
	components: {
		Task,
		Project,
	},

	setup() {
		const contextMenu = Store.get('header.contextMenu');

		return {
			// data
			contextMenu,
		};
	},
});
