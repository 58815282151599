import axios from 'axios';

import Task from '@/models/task.model';

import CreateTaskRequest from '@/requests/create-task.request';
import UpdateTaskRequest from '@/requests/update-task.request';
import BulkUpdateTasksRequest from '@/requests/bulk-update-tasks.request';
import BulkDeleteTasksRequest from '@/requests/bulk-delete-tasks.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class TaskService {
	public static async get(): Promise<Task[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/tasks`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar uppgávurnar`, error);

				Toast.error('Tað eydnaðist ikki at heinta uppgávur');

				return false;
			});
	}

	public static async search(query: string): Promise<Task[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/tasks/search?q=${query}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at leita eftir uppgávum við query ${query}`, error);

				Toast.error('Tað eydnaðist ikki at leita');

				return false;
			});
	}

	public static async show(id: number): Promise<Task> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/tasks/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta uppgávuna við id ${id}`, error);

				Toast.error('Tað eydnaðist ikki at heinta uppgávuna');

				return false;
			});
	}

	public static async create(createTaskRequest: CreateTaskRequest): Promise<Task> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/tasks`, createTaskRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna uppgávuna', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateTaskRequest: UpdateTaskRequest): Promise<Task> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/tasks/${id}`, updateTaskRequest)
			.then((response) => {
				Toast.success('Dagfør uppgávu', 'Uppgávan er dagførd');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta uppgávuna við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/tasks/${id}`)
			.then((response) => {
				Toast.success('Uppgáva strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika uppgávuna við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async bulkUpdate(bulkUpdateTaskRequest: BulkUpdateTasksRequest): Promise<ServerResponse> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/tasks`, bulkUpdateTaskRequest)
			.then((response) => {
				Toast.success('Dagføra uppgávur', 'Uppgávur eru dagførdar');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at bulk dagføra uppgávurnar`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async bulkRemove(bulkDeleteTasksRequest: BulkDeleteTasksRequest): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/tasks`, {
				data: bulkDeleteTasksRequest,
			})
			.then((response) => {
				Toast.success('Strika uppgávur', 'Uppgávurnar eru strikaðar');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at bulk sletta uppgávurnar`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
