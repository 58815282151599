import axios from 'axios';

import TaskStatus, { CreateTaskStatus, UpdateTaskStatus } from '@/models/task-status.model';
import Toast from '@/toast';

export default class TaskStatusService {
	public static async index(project_id: number): Promise<TaskStatus[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects/${project_id}/task-statuses`)
			.then((response) => {
				return response.data.sort((a: TaskStatus, b: TaskStatus) => a.order - b.order);
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at heinta status til uppgávuna');

				return false;
			});
	}

	public static async show(task_status_id: number): Promise<TaskStatus[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/task-statuses/${task_status_id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at heinta status');

				return false;
			});
	}

	public static async create(task_status: CreateTaskStatus): Promise<TaskStatus> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/task-statuses`, task_status)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at stovna status');

				return false;
			});
	}

	public static async update(id: number, task_status: UpdateTaskStatus): Promise<TaskStatus> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/task-statuses/${id}`, task_status)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at broyta rað hjá status');

				return false;
			});
	}

	public static async bulkUpdateOrder(task_statuses: TaskStatus[]): Promise<TaskStatus[]> {
		const ids = task_statuses.map((task_status) => task_status.id);

		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/task-statuses/order`, { task_statuses_ids: ids })
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at heinta status til uppgávuna');

				return false;
			});
	}

	public static async delete(task_status_id: number): Promise<TaskStatus> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/task-statuses/${task_status_id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				Toast.error('Tað bar ikki til at strika status');

				return false;
			});
	}
}
