import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute right-0 w-56 py-3 rounded-md shadow-lg text-gray-900 bg-white transition-all transform overflow-hidden", !_ctx.contextMenu?.active && '-translate-y-1 opacity-0 pointer-events-none'])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
						() => {
							if (!_ctx.contextMenu) {
								return;
							}

							_ctx.contextMenu.showArchive = !_ctx.contextMenu?.showArchive;
							_ctx.contextMenu.active = false;
						}
					, ["stop","prevent"])),
          class: "block px-7 py-3 hover:bg-dark-400/5 text-sm"
        }, [
          _createElementVNode("span", {
            textContent: _toDisplayString(_ctx.contextMenu?.showArchive ? 'Vís nýggjar' : 'Vís arkiv')
          }, null, 8, _hoisted_2)
        ])
      ])
    ], 2)
  ]))
}