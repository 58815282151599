import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: _ctx.name },
    onClick: _ctx.closeSidebar,
    class: _normalizeClass(["flex items-center py-3 text-gray-500 hover:text-gray-700 text-sm border-l-[3px] border-white transition", {
			'bg-background border-blue-400': _ctx.isActive,
			'hover:bg-background/40': !_ctx.isActive,
			'justify-center': _ctx.collapsed,
			'px-6': !_ctx.collapsed,
		}])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["grid place-items-center w-5 h-6", { 'mr-3': !_ctx.collapsed }])
      }, [
        _createVNode(_component_SVG, {
          name: _ctx.icon,
          class: "w-4 h-4"
        }, null, 8, ["name"])
      ], 2),
      _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.label), 513), [
        [_vShow, !_ctx.collapsed]
      ])
    ]),
    _: 1
  }, 8, ["to", "onClick", "class"]))
}