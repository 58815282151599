
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router';

import Store from '@/store';

import useEmitter from '@/compostables/useEmitter';

import SVG from '@/components/SVG.vue';
import Notifications from '@/components/layout/Notifications.vue';
import ContextMenu from '@/components/ContextMenu.vue';

export default defineComponent({
	components: {
		SVG,
		Notifications,
		ContextMenu,
	},

	setup() {
		const route = useRoute();
		const emitter = useEmitter();

		const title = Store.get('header.title');
		const nav = Store.get('header.nav');
		const options = Store.get('header.options');
		const contextMenu = Store.get('header.contextMenu');

		function handleOnOptionClick(action: string) {
			emitter.emit(action);
		}

		return {
			// data
			contextMenu,
			title,
			nav,
			options,
			currentRoute: route.name,

			// methods
			toggleSidebar() {
				Store.set('sidebar', true);
			},

			handleOnOptionClick,
		};
	},
});
