
import { defineComponent } from 'vue';

import Toast from '@/toast';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	components: {
		SVG,
	},

	setup() {
		const toasts = Toast.all();

		function remove(id: string) {
			Toast.remove(id);
		}

		return {
			// data
			toasts,

			// functions
			remove,
		};
	},
});
