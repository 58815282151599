
import { defineComponent, ref, watch } from 'vue';

import useEmitter from '@/compostables/useEmitter';

import { DatePicker } from 'v-calendar';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	props: {
		active: Boolean,
		eventName: String,
	},

	emits: ['back'],

	components: {
		SVG,
		DatePicker,
	},

	setup(props) {
		const emitter = useEmitter();

		const date = ref(null as Date | null);

		watch(date, (value) => {
			emitter.emit(props.eventName ?? 'bulk:date', value);
		});

		return {
			// data
			date,
		};
	},
});
