
import { defineComponent } from 'vue';

import SVG from '@/components/SVG.vue';
import useEmitter from '@/compostables/useEmitter';
import Store from '@/store';
import TaskStatus from '@/models/task-status.model';

export default defineComponent({
	props: {
		active: Boolean,
	},

	emits: ['back'],

	components: {
		SVG,
	},

	setup() {
		const emitter = useEmitter();
		const groups = Store.get('task.groups');

		function select(status: TaskStatus) {
			emitter.emit('bulk:status', status);
		}

		return {
			// data
			groups,

			// functions
			select,
		};
	},
});
