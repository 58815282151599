
import { defineComponent } from 'vue';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	props: {
		modelValue: Boolean,
		isSwitch: Boolean,
		label: String,
	},

	emits: ['toggle'],

	components: {
		SVG,
	},

	setup(_, ctx) {
		function valueChange(e: Event) {
			const target = e.target as HTMLInputElement;

			if (!target) {
				return;
			}

			ctx.emit('toggle', target.checked);
		}

		return {
			// functions
			valueChange,
		};
	},
});
