import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';
import axios from 'axios';

export default class NotificationService {
	public static async get(): Promise<Notification[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/notifications`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar fráboðanirnar`, error);

				Toast.error('Tað eydnaðist ikki at heinta tínar fráboðanir');

				return false;
			});
	}

	public static async getUnread(): Promise<Notification[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/notifications/unread`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar ólisnar fráboðanirnar`, error);

				return false;
			});
	}

	public static async getRead(): Promise<Notification[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/notifications/read`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar lisnar fráboðanirnar`, error);

				return false;
			});
	}

	public static async markAllAsRead(): Promise<ServerResponse> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/notifications/mark-all-as-read`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at marka allar fráboðanirnar sum lisnar`, error);

				Toast.error('Tað eydnaðist ikki at frámerkja fráboðanirnar sum lisnar');

				return false;
			});
	}

	public static async toggle(id: string): Promise<ServerResponse> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/notifications/${id}/toggle`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at toggla fráboðanina`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
