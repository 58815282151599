
import { defineComponent } from 'vue';

import Store from '@/store';

export default defineComponent({
	setup() {
		const contextMenu = Store.get('header.contextMenu');

		return {
			// data
			contextMenu,
		};
	},
});
