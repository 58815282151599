import axios from 'axios';
import SearchResponse from '@/responses/search.response';
import Toast from '@/toast';

export default class SearchService {
	public static async search(query: string): Promise<SearchResponse> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/search?q=${query}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at leita í skipanini við query ${query}`, error);

				Toast.error('Tað eydnaðist ikki at leita');

				return false;
			});
	}
}
