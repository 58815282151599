import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16d31af6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout grid h-screen sm:pl-72 overflow-x-hidden" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "h-[55px] sm:hidden"
}
const _hoisted_5 = {
  key: 2,
  class: "sm:hidden mb-4 text-xl text-center overflow-x-hidden whitespace-nowrap overflow-ellipsis max-w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ProjectSidebar = _resolveComponent("ProjectSidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.route.meta.parent != 'Project')
      ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.route.meta.parent == 'Project')
      ? (_openBlock(), _createBlock(_component_ProjectSidebar, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createElementVNode("main", {
        class: _normalizeClass(["relative overflow-x-auto", { 'p-6 md:p-12': !_ctx.noPadding }])
      }, [
        (_ctx.nav.length || (_ctx.options.length && _ctx.$user.can('create tasks')))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["fixed bg-background z-40 left-0 top-20 px-6 py-4 flex w-full sm:hidden justify-center items-center space-x-3", {
					'justify-between': _ctx.nav.length && _ctx.options.length && _ctx.$user.can('create tasks'),
				}])
            }, [
              (_ctx.$user.can('create tasks'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `option-${index}`,
                        onClick: ($event: any) => (_ctx.handleOnOptionClick(option.action)),
                        class: "py-2 px-3 hover:bg-gray-100 cursor-pointer rounded-md transition"
                      }, _toDisplayString(option.title), 9, _hoisted_3))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (link, index) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: `link-${index}`,
                    to: { name: link.name },
                    class: _normalizeClass(["py-2 px-3 hover:bg-gray-100 rounded-md transition", { 'text-blue-800 bg-blue-200 hover:bg-blue-300 ': link.name == _ctx.currentRoute }])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(link.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
                }), 128))
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.nav.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        (!_ctx.noPadding)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_Toast),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ])
  ], 64))
}