
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router';

import SVG from '@/components/SVG.vue';
import Search from '@/components/layout/Search.vue';
import User from '@/components/layout/User.vue';
import Store from '@/store';

export default defineComponent({
	components: {
		Search,
		SVG,
		User,
	},

	setup() {
		const route = useRoute();

		const active = Store.get('sidebar');

		return {
			// data
			route,
			active,

			// methods
			close() {
				Store.set('sidebar', false);
			},
		};
	},
});
