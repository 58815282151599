import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative z-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Task = _resolveComponent("Task")!
  const _component_Project = _resolveComponent("Project")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.contextMenu?.type == 'task')
      ? (_openBlock(), _createBlock(_component_Task, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.contextMenu?.type == 'project')
      ? (_openBlock(), _createBlock(_component_Project, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}