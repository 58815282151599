
import { defineComponent } from 'vue';

import Store from '@/store';

import Search from '@/components/layout/Search.vue';
import SVG from '@/components/SVG.vue';
import MenuItem from '@/components/layout/MenuItem.vue';
import User from '@/components/layout/User.vue';

export default defineComponent({
	components: {
		Search,
		SVG,
		MenuItem,
		User,
	},

	setup() {
		const active = Store.get('sidebar');

		return {
			// data
			active,

			// methods
			close() {
				Store.set('sidebar', false);
			},
		};
	},
});
