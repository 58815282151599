import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "checkbox group relative inline-flex items-center" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = {
  key: 2,
  class: "ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (!_ctx.isSwitch)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleValue && _ctx.toggleValue(...args))),
          class: _normalizeClass(["grid place-items-center px-[2px] w-4 h-4 rounded border cursor-pointer transition", 
					_ctx.modelValue
						? 'bg-blue border-blue group-hover:bg-blue-600'
						: 'bg-white border-dark-100 group-hover:bg-dark-50'
				])
        }, [
          _createVNode(_component_SVG, {
            name: "icons/checkmark",
            class: _normalizeClass(["text-white w-[10px]", !_ctx.modelValue && 'opacity-0'])
          }, null, 8, ["class"])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isSwitch)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleValue && _ctx.toggleValue(...args))),
          class: _normalizeClass(["flex items-center px-[2px] w-11 h-6 rounded-full transition cursor-pointer", 
					_ctx.modelValue
						? 'bg-blue-400 group-hover:bg-blue-500'
						: 'bg-dark-200 bg-opacity-80 group-hover:bg-opacity-100'
				])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-5 h-5 bg-white rounded-full transform transition shadow", _ctx.modelValue && 'translate-x-5'])
          }, null, 2)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "checkbox",
      class: "absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer",
      checked: _ctx.modelValue,
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.valueChange && _ctx.valueChange(...args)))
    }, null, 8, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}