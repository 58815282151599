
import { defineComponent, ref, onMounted, Ref, watch } from 'vue';

import NotificationService from '@/services/notification.service';

import SVG from '@/components/SVG.vue';
import Store from '@/store';
import AuthService from '@/services/auth.service';

export default defineComponent({
	components: {
		SVG,
	},

	setup() {
		const user = Store.getUser();

		const notificationsOpen = ref(false);
		const notifications: Ref<Notification[]> = ref([]);

		onMounted(() => {
			notifications.value = user.value?.notifications;
		});

		watch(user, (userValue) => {
			notifications.value = userValue?.notifications;
		});

		function handleClickAway(target: Element) {
			if (target.closest('.notifications')) {
				return;
			}

			notificationsOpen.value = false;
		}

		function handleTimeDiff(timestamp: string) {
			const createdAt = new Date(timestamp);
			const timeSince = createdAt.diff(new Date());
			if (timeSince.minutes === 0) {
				return 'Júst nú';
			}

			if (timeSince.hours === 0) {
				return `${timeSince.minutes} ${timeSince.minutes > 1 ? 'minuttir' : 'minut'} síðani`;
			}

			if (timeSince.hours < 3) {
				return `${timeSince.hours} ${timeSince.hours > 1 ? 'tímar' : 'tími'} síðani`;
			}

			if (timeSince.days === 0) {
				return `Í dag kl. ${createdAt.format('%H:%M')}`;
			}

			return `${createdAt.format('%d. %mn3. %y kl. %H:%M')}`;
		}

		async function handleNotificationClick(target: Element, id: string, url: string) {
			await NotificationService.toggle(id).then(async (toggleResponse) => {
				if (toggleResponse) {
					await AuthService.me(true);

					await NotificationService.get().then((response: Notification[]) => {
						if (response) {
							notifications.value = response;
						}
					});
				}
			});

			if (!target.classList.contains('blue-dot')) {
				if (url) {
					window.location.href = url;
					return;
				}

				window.location.href = '/notifications';
			}
		}

		async function markAll() {
			await NotificationService.markAllAsRead().then(async (response) => {
				if (response) {
					await AuthService.me(true);
				}
			});
		}

		return {
			// data
			user,
			notificationsOpen,
			notifications,

			// functions
			handleClickAway,
			handleTimeDiff,
			handleNotificationClick,
			markAll,
		};
	},
});
