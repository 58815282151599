
import { defineComponent, reactive, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import SVG from '@/components/SVG.vue';
import Checkbox from '@/components/form/Checkbox.vue';

import MeetingService from '@/services/meeting.service';
import ProjectService from '@/services/project.service';
import SearchService from '@/services/search.service';
import TaskService from '@/services/task.service';
import SearchResponse from '@/responses/search.response';

export default defineComponent({
	components: {
		SVG,
		Checkbox,
	},

	setup() {
		const route = useRoute();
		const query = ref('');
		const searchGlobally = ref(true);
		const searchResults: SearchResponse = reactive({
			projects: [],
			tasks: [],
			meetings: [],
		});

		// Check if types is searchable
		const shouldProjectSearch = computed(() => {
			return searchGlobally.value || route.name === 'Projects';
		});

		const shouldTasksSearch = computed(() => {
			return searchGlobally.value || route.name === 'ProjectTasks';
		});

		const shouldMeetingsSearch = computed(() => {
			return searchGlobally.value || route.name === 'Meetings';
		});

		// Watch query and update search results
		watch(query, async (searchQuery) => {
			if (searchQuery.length === 0) {
				setTimeout(() => {
					searchResults.projects = [];
					searchResults.tasks = [];
					searchResults.meetings = [];
				}, 50);
				return;
			}

			if (searchGlobally.value) {
				await SearchService.search(searchQuery).then((response) => {
					if (response) {
						searchResults.projects = response.projects;
						searchResults.tasks = response.tasks;
						searchResults.meetings = response.meetings;
					}
				});

				return;
			}

			if (shouldProjectSearch.value) {
				await ProjectService.search(searchQuery).then((response) => {
					if (response) {
						searchResults.projects = response;
					}
				});
			}

			if (shouldTasksSearch.value) {
				await TaskService.search(searchQuery).then((response) => {
					if (response) {
						searchResults.tasks = response;
					}
				});
			}

			if (shouldMeetingsSearch.value) {
				await MeetingService.search(searchQuery).then((response) => {
					if (response) {
						searchResults.meetings = response;
					}
				});
			}
		});

		// Handle search results, add blue text cursive where query matches result
		function renderContent(content: string) {
			if (!content) {
				return '';
			}

			const regex = new RegExp(query.value, 'gi');
			const match = content.match(regex);

			if (match) {
				return content.replace(regex, `<span class="text-blue italic">${match[0]}</span>`);
			}

			return content;
		}

		// Close search
		function closeSearch(target: Element) {
			setTimeout(() => {
				query.value = '';

				target.closest('.relative')?.querySelector('input')?.blur();
			}, 50);
		}

		// Handle number of searchresults
		function handleLength(length: number) {
			return length > 10 ? 10 : length;
		}

		return {
			// data
			query,
			searchGlobally,
			searchResults,
			shouldProjectSearch,
			shouldTasksSearch,
			shouldMeetingsSearch,

			// functions
			renderContent,
			closeSearch,
			handleLength,
		};
	},
});
