
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router';

import useEmitter from '@/compostables/useEmitter';

import Store from '@/store';
import Toast from '@/components/layout/Toast.vue';

import Header from '@/components/layout/Header.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import ProjectSidebar from '@/components/layout/project/Sidebar.vue';

export default defineComponent({
	props: ['noPadding'],

	components: {
		Header,
		ProjectSidebar,
		Sidebar,
		Toast,
	},

	setup() {
		const route = useRoute();
		const emitter = useEmitter();

		const title = Store.get('header.title');
		const nav = Store.get('header.nav');
		const options = Store.get('header.options');

		function handleOnOptionClick(action: string) {
			emitter.emit(action);
		}

		return {
			// data
			route,
			title,
			nav,
			options,
			currentRoute: route.name,

			// functions
			handleOnOptionClick,
		};
	},
});
