
import { defineComponent, onMounted, Ref, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import useEmitter from '@/compostables/useEmitter';

import SVG from '@/components/SVG.vue';
import User from '@/models/user.model';
import Store from '@/store';
import ProjectService from '@/services/project.service';

export default defineComponent({
	props: ['active'],

	emits: ['back'],

	components: {
		SVG,
	},

	setup(props) {
		const route = useRoute();
		const emitter = useEmitter();

		const projectUsers: Ref<User[]> = ref([]);
		const user = Store.get('user');

		const query = ref('');

		const input: Ref = ref(null);

		const index = ref(0);

		const users = computed(() => {
			if (!projectUsers.value) {
				return [];
			}

			if (!projectUsers.value) {
				return [];
			}

			const lowerCaseQuery = query.value.toLowerCase();

			// filter out the users that do not match the query
			return projectUsers.value.filter((user: User) => getFullName(user).toLowerCase().includes(lowerCaseQuery));
		});

		onMounted(async () => {
			if (user.value.can('view users')) {
				const projectId = Number(route.params.id);

				await ProjectService.show(projectId).then((response) => {
					if (response.members) {
						projectUsers.value = response.members;
					}
				});
			}
		});

		watch(
			() => props.active,
			(value) => {
				if (value) {
					setTimeout(() => {
						input.value?.focus();
					}, 250);
				}
			}
		);

		function getFullName(user: User): string {
			return `${user.first_name} ${user.last_name}`;
		}

		function select(user: User) {
			emitter.emit('bulk:user', user);
		}

		function selectUserAtCurrentIndex() {
			select(users.value[index.value]);
		}

		function arrowUp() {
			if (index.value <= 0) {
				return;
			}

			index.value--;
		}

		function arrowDown() {
			if (index.value >= users.value?.length - 1) {
				return;
			}

			index.value++;
		}

		return {
			// data
			query,
			input,
			users,
			index,

			// functions
			select,
			getFullName,
			selectUserAtCurrentIndex,
			arrowUp,
			arrowDown,
		};
	},
});
